.step-item {
  @apply relative flex flex-col items-center w-24 sm:w-36;
}

.step-item:not(:first-child)::before {
  @apply content-[''] bg-mainColor-200 absolute h-[3px] top-5 left-[-50%] w-full z-0;
}

.step {
  @apply w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center bg-slate-500 rounded-full font-semibold text-white z-10;
}

.active .step {
  @apply bg-mainColor-500;
}

.complete .step {
  @apply bg-green-600;
}

.complete p {
  @apply text-mainColor;
}

.complete:not(:first-child)::before,
.active:not(:first-child)::before {
  @apply bg-green-600;
}

@media (max-width: 640px) {
  .step-item:not(:first-child):before {
    display: none;
  }
}